import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';


const ClientPrivateRoute = ({ auth }) => {

    const params = useParams();
    const companyParamsId = params.companyId; 

    return (
        <>
            {auth ? <Outlet /> :
                <>
                    {localStorage.setItem("company_id", companyParamsId)}
                    {localStorage.setItem('AfterLoginUrl', window.location.pathname)}
                    <Navigate to="/" />
                </>
            }
        </>
    );
}

export default ClientPrivateRoute;