// export const API_URL = 'http://localhost:4000/';
export const API_URL = 'http://ec2-18-210-106-78.compute-1.amazonaws.com/';

// export const socket_url = 'https://2383-103-177-173-40.in.ngrok.io'; 
export const socket_url = 'http://localhost:3000';

export const userType = "Admin".toLowerCase();

export const apiHelper = {
    loginApi: '/user/login',
    employeeRegisterApi: '/user/register',
    employeeDashboardApi: '/user/dashboard',


    createCompany: "/company/createcompany",
    companyList: "/company/companylist",
    updateCompany: "/company/updatecompany",
    getCompanyById: "/company/getcompanybyid",


    getEmployee: "user/userList",
    addEmployee: "user/createUser",
    updateEmployee: "user/updateUser",
    deleteEmployee: "user/userdelete",
    deleteDevice: "user/removeimeinumber",
    changeEmployeeStatus: 'user/changeStatus',
    changeAllowClockinStatus: 'user/changeAllowClockinStatus',
    updateManagerFromList: 'user/assignManager',
    exportEmployee: 'user/exportEmployee',
    viewTodayAttendance: 'user/viewTodayAttendance',
    resetpassword: 'user/resetpassword',
    deletedEmployee: 'user/deletedEmployee',

    createSiteLocation: 'sitelocation/createsitelocation',
    updateSiteLocation: 'sitelocation/updatesitelocation',
    deleteSiteLocation: 'sitelocation/deletesitelocation',
    getSiteLocation: 'sitelocation/sitelocationlist',
    getSiteLocationUserList: 'sitelocation/sitelocationuserlist',
    getSiteLocationUserDropDownList: 'user/userdropdown',
    addSiteLocationUser: 'sitelocation/addsitelocationuser',
    deleteSiteLocationUser: 'sitelocation/deletesitelocationuser',
    deleteSiteMultiLocationUser: 'sitelocation/deletemultisitelocationuser',
    updateEmployeeSiteLocation: 'sitelocation/updatesitelocationuser',
    transferEmployee: 'sitelocation/changeusersitelocation',
    transferMultiEmployee: 'sitelocation/changemultiusersitelocation',
    updatetheShiftTimeEmployee: 'sitelocation/updatesitelocationuser',
    sitelocationmapview: 'sitelocation/sitelocationmapview',
    userlocationmapview: 'sitelocation/userlocationmapview',

    employeeInfoDateWise: 'attendance/getAttendanceBySiteLocation',
    employeeAbsentIndoDateWise: 'user/viewTodayAttendance',
    outOfLocationReport: 'attendance/outOfLocationReport',
    idleTimeReport: 'attendance/idleTimeReport',
    latePunching:'attendance/lateClockinEmployee',
    fullCalender: 'sitelocation/getmonthlyreport',
    employeeHistory: 'attendance/getUserAttendanceHistory',
    attendanceDataById: 'attendance/attendanceDataById',
    lastLocationData: 'attendance/lastLocationData',
    transferEmployeeLog: 'sitelocation/userSiteLocationHistory',
    lastClockIn: 'attendance/lastClockinData',

    export: 'attendance/exportData',
    exportTodayAttendance: 'user/exportTodayAttendance',
    exportOutOfLocationReport: 'attendance/exportOutOfLocationReport',
    exportIdleTimeReport: 'attendance/exportIdleTimeReport',
    exportLateClockinEmployee: 'attendance/exportLateClockinEmployee',

    leaveList: 'leave/leavelist',
    leaveLogList: 'leave/leaveloglist',
    getleavebyid: 'leave/getleavebyid',
    updateLeaveStatus: 'leave/updateleavestatus',

    clockInImage: 'attendance/attendanceImage',
}


// export const userType = "customer".toLowerCase();

// http://ec2-184-73-131-56.compute-1.amazonaws.com/user/login