import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Auth from '../Route/Auth';
import ClientPrivateRoute from './ClientPrivateRoute';
import LazyRouteElement from './LazyRouteElement';
import { userRoute } from './DynamicRoute';
import { fetchUserType, getCompanyID } from '../Service/service';
import Main from "../components/layout/Main";

const AfterLoginRoute = () => {

    const userType = fetchUserType();
    const companyID = getCompanyID();

    return (
        <Main>
            <Routes>
                <Route element={<ClientPrivateRoute auth={Auth.isAuthenticated()} />} >
                    {userRoute?.length && userRoute.map((item) => {
                        const { path, exact = false, component, access } = item;

                        if (!access?.includes(userType)) {
                            return false;
                        }

                        return (
                            <Route
                                key={component}
                                path={path}
                                element={<LazyRouteElement component={component} />}
                                exact={exact}
                            />
                        );
                    })}
                </Route>

                {
                    userType === 'admin'?
                        <Route path="*" element={<Navigate replace={true} to={`/admin/companies`} />} />
                        :
                        <Route path="*" element={<Navigate replace={true} to={`/${userType}/${companyID}`} />} />
                }
            </Routes>
        </Main>
    );
};

export default AfterLoginRoute;
