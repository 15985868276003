import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { fetchUserType, getCompanyID } from '../Service/service';

const ClientPublicRoute = ({ auth }) => {

    const userType = fetchUserType();
    const companyId = getCompanyID();

    return (
        <>
            {auth ? userType === 'admin' ?
                <Navigate to="/admin/companies" />
                :
                <Navigate to={`company/${companyId}`} />
                : <Outlet />}
        </>
    );
}

export default ClientPublicRoute;