import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc")
var timezone = require("dayjs/plugin/timezone")
// var advanced = requires("dayjs/plugin/advancedFormat")

dayjs.extend(utc)
dayjs.extend(timezone)
// dayjs.extend(advanced)

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const generateRandomId = () => {
  let token = fetchAuthToken()
  const decodedJwt = parseJwt(token?.replace("Bearer ", ""));
  return localStorage.setItem("randomID", decodedJwt?.user_code)
};

// export const generateRandomId = () => {
//   const randomNumber = Math.floor(1000000 + Math.random() * 9000000);
//   return randomNumber.toString().substring(0, 7);
// };

export const fetchAuthToken = () => {
  return localStorage.getItem("museAuth")
}

export const fetchUserType = () => {
  return localStorage.getItem("userType") || 'company'
}
export const fetchUserRole = () => {
  return localStorage.getItem("role") || 'company'
}

export const dateFormate = (customDate) => {
  if (!customDate) {
    return ""
  }
  return dayjs(customDate).format("DD-MM-YYYY")
}

export const removeParamsFromURL = (pathname) => {
  return pathname.split('/')?.length === 4 ? pathname.substring(0, pathname.lastIndexOf('/')) : pathname;
}

export const getCompanyID = () => {
  const userDetails = localStorage.getItem("museUserDetails");
  if (localStorage.getItem("company_id")) {
    return localStorage.getItem("company_id")
  }
  if (userDetails) {
    const { companyId } = JSON.parse(userDetails);
    return companyId
  }
  return false
}

export const getLoginName = () => {
  const userDetails = localStorage.getItem("museUserDetails");

  if (userDetails) {
    const { firstName, lastName, title } = JSON.parse(userDetails);
    return `${title} ${firstName} ${lastName}`
  }
  return 'Hello'
}

export const getStringAfterLastSlash = (str, index) => {
  var parts = str.split('/');
  if (index) {
    return parts[parts.length - index];
  }
  return parts[parts.length - 1];
}

export const isMobileDevice = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  return regexp.test(details);
}

export const convertDateFormat = (date, format = 'YYYY-MM-DD') => {
  if (date) {
    return dayjs(date).format(format);
  }
  return date
}

export function convertMinutesToHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if(hours === 0) {
      return `${minutes}m`
  } else {
      return `${hours}h ${minutes}m`
  }
}

export const convertDateFormatTimeZone = (date, format = 'YYYY-MM-DD') => {
  if (date) {
    return dayjs(new Date(date)).tz('Africa/Dakar').format(format);
  }
  return date
}

export const convertDateFormatIndianTimeZone = (date, format = 'YYYY-MM-DD') => {
  if (date) {
    return dayjs(date).utcOffset("+05:30").format(format);
  }
  return date
}

export const calculateDays = (formDate, toDate) => {
  const date1 = dayjs(formDate);
  const date2 = dayjs(toDate);

  let hours = date2.diff(date1, 'hours');
  let days = Math.floor(hours / 24);
  days = isNaN(days) ? -1 : days
  return (days + 1 < 1) ? (days + 1) + " day" : (days + 1) + " days"
}

export const statusColor = (status) => {
  if (status === "Approved") {
    return '#14A44D'
  } else if (status === "Pending") {
    return '#3B71CA'
  } else if (status === "Rejected") {
    return '#DC4C64'
  } else {
    return '#727171'
  }
}

export const getMonthDateRange = () => {
  const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
  const endDate = dayjs().endOf('month').format('YYYY-MM-DD');
  return { startDate, endDate };
}
