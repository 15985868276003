import FallbackLoader from '../components/layout/FallbackLoader';
import React, { lazy, Suspense } from 'react';

// Component for lazy loading with suspense
const LazyRouteElement = ({ component }) => {
  const Component = lazy(() => import(`../pages/${component}`));

  return (
    <Suspense fallback={<FallbackLoader />}>
      <Component />
    </Suspense>
  );
};

export default LazyRouteElement;
