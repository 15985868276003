import axios from 'axios';
import jwtDecode from 'jwt-decode';


class Auth {
    constructor() {
        this.authenticated = false
    }

    getAccessToken = () => {
        if (localStorage.getItem('museAuth')) {
            return localStorage.getItem("museAuth");
        }
    };

    logout() {
        localStorage.removeItem('role');
        localStorage.removeItem('museAuth');
        localStorage.removeItem('userType');
        localStorage.removeItem('company_id');
        localStorage.removeItem('AfterLoginUrl');
        localStorage.removeItem('museUserDetails');
        delete axios.defaults.headers.common['Authorization'];
    }


    isAuthenticated = () => {
        const access_token = this.getAccessToken();

        // return true

        if (!access_token) {
            // this.logout();
            return false;
        }

        const decoded = jwtDecode(access_token);
        const experTime = (decoded?.iat * 1000) + (7 * 24 * 60 * 60 * 1000);
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
            this.logout();
            return false;
        }

        if (experTime < (currentTime * 1000)) {
            this.logout();
            return false;
        }

        axios.defaults.headers.common['Authorization'] = access_token;
        return true;

    }

}

const instance = new Auth();

export default instance;