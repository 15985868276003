import { message } from 'antd';
import axios from 'axios';

class CustomMiddleService {

    postData = async (path, data) => {
        try {
            let response = await axios.post(path, data);
            if (response.status === 200) {
                return response.data;
            } else {
                return false;
            }
        }
        catch (error) {
            message.error(error?.response?.data?.message ? error?.response?.data?.message : 'Something Went Wrong', 5);
            // console.error(error);
        }
    }

    getData = async (path) => {
        try {
            let response = await axios.get(path);
            if (response.status === 200) {
                return response.data;
            } else {
                return false;
            }
        }
        catch (e) {
            console.error(e);
        }
    }

}

const MiddleService = new CustomMiddleService();

export default MiddleService;
