import { Route, Routes, Navigate } from 'react-router-dom';
// import Login from '../Pages/Login';
import Auth from '../Route/Auth';
import ClientPublicRoute from './ClientPublicRoute';
import SignUp from "../pages/Register/SignUp";
import SignIn from "../pages/Login/SignIn";

const BeforeLoginRoute = () => {
    return (
        <>
            <Routes>
                <Route element={<ClientPublicRoute auth={Auth.isAuthenticated()} />} >
                    <Route path="/" element={<SignIn />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/sign-up" element={<SignUp />} />
                </Route>
                <Route path="*" element={<Navigate replace={true} to="/" />} />
            </Routes>
        </>
    );
};

export default BeforeLoginRoute;
