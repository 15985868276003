export const sidebarMenu = [
    {
        url: "",
        icon: "dashboard",
        title: "Dashboard",
        activeShow: ["dashboard"],
        access: ['admin', 'company']
    },
    {
        url: "companies",
        icon: "tables",
        title: "Companies",
        activeShow: ["companies"],
        access: ['admin']
    },
    {
        url: "employees",
        icon: "tables",
        title: "Employee Info",
        activeShow: ["employees"],
        access: ['admin', 'company']
    },
    {
        url: "siteLocations",
        icon: "tables",
        title: "Site Locations",
        activeShow: ["siteLocations"],
        access: ['admin', 'company']
    },
    {
        url: "leave",
        icon: "tables",
        title: "Leave",
        activeShow: ["leave"],
        access: ['admin', 'company']
    },
    {
        url: "report",
        icon: "tables",
        title: "Report",
        activeShow: ["report"],
        access: ['admin', 'company']
    },
    {
        url: "clockInImage",
        icon: "tables",
        title: "Attendance Images",
        activeShow: ["clockInImage"],
        access: ['admin', 'company']
    }
];