export const userRoute = [
    {
        path: '/companies',
        exact: true,
        component: 'Companies',
        label: 'Company List',
        access: ['admin']
    },
    {
        path: '/:companyId',
        component: 'Dashboard/Home',
        label: 'Dashboard',
        access: ['admin', 'company']
    },
    {
        path: '/:companyId/employees',
        exact: true,
        component: 'Employee/Employees',
        label: 'Employee List',
        access: ['company', 'admin']
    },
    {
        path: '/:companyId/siteLocations',
        exact: true,
        component: 'SiteLocation/SiteLocations',
        label: 'Site Locations',
        access: ['company', 'admin']
    },
    {
        path: '/:companyId/siteLocation/:siteLocationId/fullcalender',
        exact: true,
        component: 'SiteLocation/FullCalender',
        label: 'Calender',
        access: ['company', 'admin']
    },
    {
        path: '/:companyId/map',
        exact: true,
        component: 'Map',
        label: 'Map',
        access: ['company', 'admin']
    },
    {
        path: '/:companyId/leave',
        exact: true,
        component: 'Leave/Leave',
        label: 'Leave',
        access: ['company', 'admin']
    },
    {
        path: '/:companyId/leave/edit/:leave_id',
        exact: true,
        component: 'Leave/Leave',
        label: 'Leave',
        access: ['company', 'admin']
    },
    {
        path: '/:companyId/report',
        exact: true,
        component: 'Report/Report',
        label: 'Report',
        access: ['company', 'admin']
    },
    {
        path: '/:companyId/clockInImage',
        exact: true,
        component: 'ClockInImage/ClockInImage',
        label: 'Attendance Images',
        access: ['company', 'admin']
    },
    {
        path: '/:companyId/today-report',
        exact: true,
        component: 'TodayReport/Report',
        label: 'Report',
        access: ['company', 'admin']
    },
];