import axios from 'axios';
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { Route } from "react-router-dom";
import { API_URL } from './API/CommonAPI';
import AfterLoginRoute from './Route/AfterLoginRoute';
import BeforeLoginRoute from './Route/BeforeLoginRoute';
import { BrowserRouter as Router, Routes } from 'react-router-dom';

function App() {

  axios.defaults.baseURL = API_URL;

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/admin/*" element={<AfterLoginRoute />} />
          <Route path="/company/*" element={<AfterLoginRoute />} />
          <Route path="/*" element={<BeforeLoginRoute />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
